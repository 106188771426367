import React, { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import { message } from 'antd';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../Api/useApi.ts';

import { Tabs, TabsList, TabsTrigger } from '../Components/ui/tabs.tsx';
import { Switch } from '../Components/ui/switch.tsx';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '../Components/ui/breadcrumb.tsx';
import { Button } from '../Components/ui/button.tsx';
import { Separator } from '../Components/ui/separator.tsx';
import {
    ToggleGroup,
    ToggleGroupItem,
} from '../Components/ui/toggle-group.tsx';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '../Components/ui/dialog.tsx';
import { ProModal } from '../Components/ProModal.tsx';
import { PricingModal } from '../Components/PricingModal.tsx';

import {
    QUESTION_OBJECTS,
    QUESTION_TYPES,
} from '../Constants/questionTypes.js';

import { useFormSubmissionsContext } from '../Contexts/FormSubmissionsContext.tsx';

import { LayersIcon } from '@radix-ui/react-icons';
import {
    ArrowUpRightIcon,
    BoxIcon,
    DownloadIcon,
    PanelLeftCloseIcon,
    RefreshCcwIcon,
    TrashIcon,
} from 'lucide-react';

const FixedFields = [
    {
        id: uuidv4(),
        title: 'Date',
        type: 'date',
        checked: true,
    },
    {
        id: uuidv4(),
        title: 'State',
        type: 'status',
        checked: true,
    },
];

const FormResultsLayout = ({ children }) => {
    const api = useApi();
    const { urlId } = useParams();
    const { setForm, setFields } = useFormSubmissionsContext();
    const { data: formResponse } = useQuery({
        queryKey: ['form', urlId],
        queryFn: () =>
            api.revisions.getOneRevision(
                urlId as string,
                sessionStorage.getItem('selectedWorkSpace')!,
            ),
        staleTime: Infinity,
    });

    useEffect(() => {
        // Extract the form from the form response & assign it to the context
        const form = formResponse?.data?.form || {};
        setForm(form);

        // Extract the fields from the form response & assign them to the context
        const formFields: any[] = formResponse?.data?.form?.fields || [];
        const formattedFields = formFields.map((field) => ({
            id: field.id,
            title: field.title,
            type: field.type,
            checked: true,
        }));
        // Add the fixed fields to the beginning of the fields array
        formattedFields.unshift(...FixedFields);
        setFields(formattedFields);
    }, [formResponse?.data]);

    return (
        <div className="relative h-screen overflow-hidden text-left">
            <Navbar
                formId={formResponse?.data?.form?._id}
                formTitle={formResponse?.data?.form?.title}
            />
            <div className="absolute top-[50px] flex flex-row h-[calc(100vh-56px)] gap-4 w-full px-4 py-2">
                <Sidebar />
                <div className="flex-grow overflow-x-hidden overflow-y-hidden p-3 bg-white rounded-[12px] drop-shadow-md border border-gray-100">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default FormResultsLayout;

function Navbar({ formId, formTitle }) {
    return (
        <nav className="fixed z-[40] h-[56px] top-0 left-0 right-0 flex flex-row items-center justify-between px-4 py-3">
            <div className="flex flex-row items-center gap-5">
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink
                                href="/"
                                className="flex flex-row items-center text-xs font-medium text-gray-800 transition duration-150 hover:text-blue-600"
                            >
                                <BoxIcon className="w-3 h-3 mr-1" />
                                Workspace
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbLink
                                href={`/form-editor/${formId}`}
                                className="flex flex-row items-center text-xs font-medium text-gray-800 transition duration-150 hover:text-blue-600"
                            >
                                <BreadcrumbPage>{formTitle}</BreadcrumbPage>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage className="text-xs text-gray-800">
                                Submissions
                            </BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
                {/* <Link to={`/form-editor/${formId}`}>
          <Button variant="outline" className="text-sm h-[36px]">
            Editor <ArrowUpRightIcon className="w-4 h-4 ml-2" />
          </Button>
        </Link> */}
            </div>

            <div className="flex flex-row items-center gap-2">
                {/* <StatusIndicator /> */}
                <Link to={`/form-editor/${formId}`}>
                    <Button variant="outline" className="text-sm h-[36px]">
                        Editor <ArrowUpRightIcon className="w-4 h-4 ml-2" />
                    </Button>
                </Link>
                {/* <Button>Publish</Button> */}
            </div>
        </nav>
    );
}

function Sidebar() {
    const { urlId } = useParams();
    const queryClient = useQueryClient();
    const [activeTab, setActiveTab] = useState('submissions');
    const [openProModal, setOpenProModal] = useState(false);
    const [openPricingModal, setOpenPricingModal] = useState(false);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const {
        openSidebar,
        setOpenSidebar,
        loading,
        setLoading,
        fields,
        setSelection,
        setFields,
        selectedFields,
    } = useFormSubmissionsContext();

    const api = useApi();
    const [messageApi] = message.useMessage();

    const { mutate: deleteResponseMutation, isPending: isDeleting } =
        useMutation({
            mutationFn: ({
                formId: urlId,
                responseIds,
            }: {
                formId: string;
                responseIds: string[];
            }) => api.forms.deleteFormResponses(urlId, responseIds),
            onSuccess: (data) => {
                messageApi.success('Responses deleted successfully');
                setOpenDeleteConfirmation(false);
                queryClient.invalidateQueries({ queryKey: ['responses'] });
                setSelection('none');
            },
            onError: (error: any) => {
                if (error.response) {
                    messageApi.error(` ${error.response.data.detail}`);
                } else {
                    messageApi.error(
                        'Could not delete responses: An unexpected error occurred',
                    );
                }
                console.error('Error details:', error);
            },
        });

    const { mutate: exportResponsesMutation, isPending: isExporting } =
        useMutation({
            mutationFn: ({
                workspaceId,
                formId,
                responseIds,
            }: {
                formId?: string;
                workspaceId: string;
                responseIds?: string[];
            }) => api.forms.exportResponses(workspaceId, formId, responseIds),
            onSuccess: (data) => {
                const exportFile = data?.data?.file_url;
                if (exportFile) {
                    // Create an anchor element
                    const anchor = document.createElement('a');

                    // Set the href to the file URL
                    anchor.href = exportFile;

                    // Set the download attribute without a filename to keep the original file name
                    anchor.setAttribute('download', '');

                    // Append the anchor to the body (optional, for some browsers)
                    document.body.appendChild(anchor);

                    // Trigger a click event to start the download
                    anchor.click();

                    // Remove the anchor from the DOM
                    document.body.removeChild(anchor);
                    messageApi.success('Responses exported successfully');
                }
                queryClient.invalidateQueries({ queryKey: ['responses'] });
            },
            onError: (error: any) => {
                if (error.response) {
                    messageApi.error(` ${error.response.data.detail}`);
                } else {
                    messageApi.error(
                        'Could not export responses: An unexpected error occurred',
                    );
                }
                console.error('Error details:', error);
            },
        });

    const handleOnFieldValueChange = (id: string, value: boolean) => {
        setFields(
            fields.map((field) =>
                field.id === id ? { ...field, checked: value } : field,
            ),
        );
    };

    const handleOnFieldValuesChange = (values: string[]) => {
        // Field types presented in the values array will be disabled other will be enabled
        const modifiedFields: any[] = [];
        for (const field of fields) {
            modifiedFields.push({
                ...field,
                checked: !values.includes(field.type),
            });
        }
        setFields(modifiedFields);
    };

    const handleOnRefresh = async () => {
        setLoading(true);
        await queryClient.invalidateQueries({ queryKey: ['formResponses'] });
        setLoading(false);
    };

    const handleOnDeleteResponses = () => {
        if (!urlId) return;
        deleteResponseMutation({
            formId: urlId,
            responseIds: selectedFields.map((field) => field.id),
        });
    };

    const handleOnExportAll = () => {
        if (!urlId) return;
        exportResponsesMutation({
            workspaceId: sessionStorage.getItem('selectedWorkSpace')!,
            formId: urlId,
        });
    };

    const handleOnExportSelected = () => {
        if (!urlId) return;
        exportResponsesMutation({
            workspaceId: sessionStorage.getItem('selectedWorkSpace')!,
            responseIds: selectedFields.map((field) => field.id),
        });
    };

    const handleOnTabChange = (value: string) => {
        if (value === 'analytics') {
            setOpenProModal(true);
            setActiveTab('analytics');
        }
    };

    if (!openSidebar)
        return (
            <div className="relative">
                <div className="flex flex-col gap-2">
                    <Button
                        variant="outline"
                        size="icon"
                        onClick={() => setOpenSidebar(!openSidebar)}
                        className="w-[36px] h-[36px]"
                    >
                        <PanelLeftCloseIcon className="w-3 h-3 text-[#2563EB]" />
                    </Button>
                    <Button
                        variant="outline"
                        size="icon"
                        onClick={handleOnExportAll}
                        disabled={isExporting}
                        className="w-[36px] h-[36px]"
                    >
                        <DownloadIcon className="w-3 h-3" />
                    </Button>
                    <Button
                        variant="outline"
                        size="icon"
                        disabled={loading}
                        onClick={handleOnRefresh}
                        className="w-[36px] h-[36px]"
                    >
                        <RefreshCcwIcon className="w-3 h-3" />
                    </Button>
                </div>
                <Separator className="my-4" />
                <ToggleGroup
                    type="multiple"
                    onValueChange={handleOnFieldValuesChange}
                    className="flex flex-col gap-1"
                >
                    {fields.map((field) => (
                        <ToggleGroupItem value={field.type} className="p-0 m-0">
                            <CollapsedSidebarSetting
                                key={field.id}
                                type={field.type}
                                value={field.checked}
                                onValueChange={(value) =>
                                    handleOnFieldValueChange(field.id, value)
                                }
                            />
                        </ToggleGroupItem>
                    ))}
                </ToggleGroup>
            </div>
        );

    return (
        <div className="relative h-full">
            <div className="sticky top-0 left-0 right-0 flex flex-col gap-2 h-fit">
                <div className="flex flex-row items-center gap-2">
                    <Tabs
                        defaultValue="submissions"
                        className="w-[244px]"
                        value={activeTab}
                        onValueChange={handleOnTabChange}
                    >
                        <TabsList className="w-full h-[36px]">
                            <TabsTrigger
                                value="analytics"
                                className="w-full text-xs"
                            >
                                Analytics
                            </TabsTrigger>
                            <TabsTrigger
                                value="submissions"
                                className="w-full text-xs font-semibold"
                            >
                                Submissions
                            </TabsTrigger>
                        </TabsList>
                    </Tabs>
                    <Button
                        variant="outline"
                        size="icon"
                        onClick={() => setOpenSidebar(!openSidebar)}
                        className="w-[36px] h-[36px]"
                    >
                        <PanelLeftCloseIcon className="w-3 h-3 text-[#2563EB]" />
                    </Button>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <Button
                        variant="outline"
                        className="w-[244px] h-[36px] text-xs"
                        disabled={isExporting}
                        onClick={handleOnExportAll}
                    >
                        <DownloadIcon className="w-3 h-3 mr-2" /> Download CSV
                    </Button>
                    <Button
                        variant="outline"
                        size="icon"
                        disabled={loading}
                        onClick={handleOnRefresh}
                        className="w-[36px] h-[36px]"
                    >
                        <RefreshCcwIcon className="w-3 h-3" />
                    </Button>
                </div>
            </div>

            <div className="absolute top-[108px] left-0 right-0 h-[calc(100vh-250px)] overflow-x-hidden overflow-y-auto">
                <div className="sticky top-0 left-0 right-0 z-[10] bg-white">
                    <p className="flex flex-row items-center gap-2 text-sm font-semibold">
                        <LayersIcon className="w-4 h-4" />
                        Submissions Table Settings
                    </p>
                    <p className="text-xs text-gray-700">
                        Control submissions table data visibility
                    </p>
                </div>

                {fields.length > 0 && (
                    <div className="flex flex-col gap-1 mt-3">
                        {fields.map((field) => (
                            <SidebarSetting
                                key={field.id}
                                title={
                                    field.type === QUESTION_TYPES.AI_STATEMENT
                                        ? 'AI Statement'
                                        : field.title
                                }
                                type={field.type}
                                value={field.checked}
                                onValueChange={(value) =>
                                    handleOnFieldValueChange(field.id, value)
                                }
                            />
                        ))}
                    </div>
                )}
            </div>

            {selectedFields.length > 0 && (
                <div className="absolute bottom-0 left-0 right-0">
                    <div className="flex flex-row items-center justify-between">
                        <div className="flex flex-col">
                            <p className="text-xs">
                                {selectedFields.length} responses selected
                            </p>
                            <div className="flex flex-row items-center gap-2 h-fit">
                                <Button
                                    variant="link"
                                    className="text-xs p-0 underline text-[#2563EB]"
                                    onClick={() => setSelection('all')}
                                >
                                    Select All
                                </Button>
                                <Button
                                    variant="link"
                                    className="text-xs p-0 underline text-[#2563EB]"
                                    onClick={() => setSelection('none')}
                                >
                                    Clear Selection
                                </Button>
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                            <Button
                                variant="outline"
                                size="icon"
                                onClick={handleOnExportSelected}
                                disabled={isExporting}
                            >
                                <DownloadIcon className="w-4 h-4" />
                            </Button>
                            <Button
                                variant="outline"
                                size="icon"
                                onClick={() => setOpenDeleteConfirmation(true)}
                            >
                                <TrashIcon className="w-4 h-4" />
                            </Button>
                            <DeleteConfirmationDialog
                                open={openDeleteConfirmation}
                                loading={isDeleting}
                                onDelete={handleOnDeleteResponses}
                                onCancel={() =>
                                    setOpenDeleteConfirmation(
                                        !openDeleteConfirmation,
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
            <ProModal
                isOpen={openProModal}
                onContinue={() => {
                    setActiveTab('submissions');
                    setOpenProModal(false);
                    setOpenPricingModal(true);
                }}
                onClose={() => {
                    setActiveTab('submissions');
                    setOpenProModal(false);
                }}
            />
            <PricingModal
                isOpen={openPricingModal}
                onClose={() => {
                    setOpenPricingModal(false);
                }}
            />
        </div>
    );
}

type DeleteConfirmationDialogProps = {
    open: boolean;
    loading: boolean;
    onDelete: () => void;
    onCancel: () => void;
};

function DeleteConfirmationDialog({
    open,
    loading,
    onDelete,
    onCancel,
}: DeleteConfirmationDialogProps) {
    const { selectedFields } = useFormSubmissionsContext();
    return (
        <Dialog open={open} onOpenChange={onCancel}>
            <DialogContent className="top-[50%] p-0">
                <DialogHeader className="px-3 pt-3">
                    <DialogTitle className="flex flex-row items-center gap-2 text-sm">
                        <TrashIcon className="w-3 h-3" />
                        Delete Response
                    </DialogTitle>
                </DialogHeader>
                <Separator className="my-3" />
                <p className="text-[#191B33] text-sm px-3">
                    Are you sure you want to delete {selectedFields.length}{' '}
                    selected responses? This action cannot be undone.
                </p>
                <Separator className="my-3" />
                <DialogFooter className="px-3 pb-3">
                    <Button
                        variant="outline"
                        className="h-[36px] text-sm"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="destructive"
                        className="bg-[#F44336] h-[36px] text-sm"
                        disabled={loading}
                        onClick={onDelete}
                    >
                        Delete Response
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

type SidebarSettingProps = {
    title: string;
    type: string;
    value: boolean;
    onValueChange: (value: boolean) => void;
};

function SidebarSetting({
    title,
    type,
    value,
    onValueChange,
}: SidebarSettingProps) {
    const question = QUESTION_OBJECTS[type];
    const Icon = question?.icon;
    const color = '#F1F2F4';

    return (
        <div className="flex flex-row items-center justify-between border border-gray-100 rounded-[8px] py-[13px] px-[8px] h-[40px]">
            <div className="flex flex-row items-center gap-3">
                <span
                    className={`bg-[${color}] w-6 h-6 flex items-center justify-center rounded-md`}
                >
                    {Icon}
                </span>
                <p className="text-xs truncate max-w-[180px]">{title}</p>
            </div>
            <Switch
                id={type}
                checked={value}
                onCheckedChange={onValueChange}
                className="w-[36px] h-[20px]"
            />
        </div>
    );
}

type CollapsedSidebarSettingProps = {
    type: string;
    value: boolean;
    onValueChange: (value: boolean) => void;
};

function CollapsedSidebarSetting({
    type,
    value,
    onValueChange,
}: CollapsedSidebarSettingProps) {
    const { fields } = useFormSubmissionsContext();
    const question = QUESTION_OBJECTS[type];
    const Icon = question?.icon;

    const isEnabled = fields.find((field) => field.type === type)?.checked;

    return (
        <Button
            variant="outline"
            size="icon"
            className={`w-[36px] h-[36px] border ${
                isEnabled ? 'border-[#CF9FFF]' : 'border-gray-200'
            }`}
        >
            <span
                className={`bg-[${question.color}] w-6 h-6 flex items-center justify-center rounded-md`}
            >
                {Icon}
            </span>
        </Button>
    );
}
