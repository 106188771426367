import React from 'react';

// Components
import { Button } from '../ui/button.tsx';

import {
    ArrowUpDown,
    Mail,
    Phone,
    InfoIcon,
    Globe,
    Check,
    ChevronDown,
    File,
    PenLine,
    Loader,
    Tag,
    Calendar,
    Text,
} from 'lucide-react';
import {
    QUESTION_OBJECTS,
    QUESTION_TYPES,
} from '../../Constants/questionTypes.js';

export type HeaderTypes =
    | 'status'
    | 'phone_number'
    | 'short_text'
    | 'contact_info'
    | 'email'
    | 'dropdown'
    | 'section'
    | 'multiple_choice'
    | 'checkbox'
    | 'group'
    | 'legal'
    | 'country'
    | 'ai_statement';

type DefaultHeaderTypes = 'tags' | 'date';

type HeaderProps = {
    title: string;
    type: DefaultHeaderTypes | HeaderTypes;
    onClick: () => void;
};

function Header({ title, type, onClick }: HeaderProps) {
    // if (type === "phone_number")
    //   return <PhoneNumber title={title} onClick={onClick} />;

    // if (type === "email") return <Email title={title} onClick={onClick} />;

    // if (type === "short_text")
    //   return <ShortText title={title} onClick={onClick} />;

    // if (type === "contact_info") return <Info title={title} onClick={onClick} />;

    // if (type === "dropdown") return <Dropdown title={title} onClick={onClick} />;

    // if (type === "multiple_choice")
    //   return <MultipleChoices title={title} onClick={onClick} />;

    // if (type === "legal") return <Legal title={title} onClick={onClick} />;

    // if (type === "country") return <Country title={title} onClick={onClick} />;

    // if (type === "section") return <Section title={title} onClick={onClick} />;

    // if (type === "status") return <Status title={title} onClick={onClick} />;

    // if (type === "tags") return <Tags title={title} onClick={onClick} />;

    // if (type === "date") return <Date title={title} onClick={onClick} />;

    const question = QUESTION_OBJECTS[type];
    const Icon = question?.icon;
    const color = '#F1F2F4';
    if (question?.type === QUESTION_TYPES.AI_STATEMENT) title = 'AI Statement';

    return (
        <div
            onClick={onClick}
            className={`text-xs pl-0 text-gray-600 hover:bg-transparent flex flex-row items-center gap-[10px] cursor-pointer`}
        >
            <div
                className={`w-6 h-6 bg-[${color}] flex items-center justify-center rounded-md`}
            >
                {Icon}
            </div>
            <p className="truncate max-w-[400px] min-w-[80px]">{title}</p>
        </div>
    );
}

export default Header;

type HeaderVariantProps = Pick<HeaderProps, 'title' | 'onClick'>;

function Status({ title, onClick }: HeaderVariantProps) {
    return (
        <Button
            variant="ghost"
            onClick={onClick}
            className="text-xs text-gray-600"
        >
            <Loader className="w-5 h-5 bg-[#BFC0C2] p-1 text-black rounded-md mr-2" />
            {title}
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
        </Button>
    );
}

function PhoneNumber({ title, onClick }: HeaderVariantProps) {
    return (
        <Button
            variant="ghost"
            onClick={onClick}
            className="text-xs text-gray-600"
        >
            <Phone className="w-5 h-5 bg-[#FFCACB] p-1 text-black rounded-md mr-2" />
            {title}
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
        </Button>
    );
}

function Email({ title, onClick }: HeaderVariantProps) {
    return (
        <Button
            variant="ghost"
            onClick={onClick}
            className="text-xs text-gray-600"
        >
            <Mail className="w-5 h-5 bg-[#FFCACB] p-1 text-black rounded-md mr-2" />
            {title}
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
        </Button>
    );
}

function ShortText({ title, onClick }: HeaderVariantProps) {
    return (
        <Button
            variant="ghost"
            onClick={onClick}
            className="text-xs text-gray-600"
        >
            <PenLine className="w-5 h-5 bg-[#B5BDF4] p-1 text-black rounded-md mr-2" />
            {title}
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
        </Button>
    );
}

function Info({ title, onClick }: HeaderVariantProps) {
    return (
        <Button
            variant="ghost"
            onClick={onClick}
            className="text-xs text-gray-600"
        >
            <InfoIcon className="w-5 h-5 bg-[#E6CEF3] p-1 text-black rounded-md mr-2" />
            {title}
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
        </Button>
    );
}

function Dropdown({ title, onClick }: HeaderVariantProps) {
    return (
        <Button
            variant="ghost"
            onClick={onClick}
            className="text-xs text-gray-600"
        >
            <ChevronDown className="w-5 h-5 bg-[#E6CEF3] p-1 text-black rounded-md mr-2" />
            {title}
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
        </Button>
    );
}

function MultipleChoices({ title, onClick }: HeaderVariantProps) {
    return (
        <Button
            variant="ghost"
            onClick={onClick}
            className="text-xs text-gray-600"
        >
            <Check className="w-5 h-5 bg-[#E6CEF3] p-1 text-black rounded-md mr-2" />
            {title}
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
        </Button>
    );
}

function Legal({ title, onClick }: HeaderVariantProps) {
    return (
        <Button
            variant="ghost"
            onClick={onClick}
            className="text-xs text-gray-600"
        >
            <File className="w-5 h-5 bg-[#E6CEF3] p-1 text-black rounded-md mr-2" />
            {title}
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
        </Button>
    );
}

function Country({ title, onClick }: HeaderVariantProps) {
    return (
        <Button
            variant="ghost"
            onClick={onClick}
            className="text-xs text-gray-600"
        >
            <Globe className="w-5 h-5 bg-[#FFCACB] p-1 text-black rounded-md mr-2" />
            {title}
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
        </Button>
    );
}

function Section({ title, onClick }: HeaderVariantProps) {
    return (
        <Button
            variant="ghost"
            onClick={onClick}
            className="text-xs text-gray-600"
        >
            <Text className="w-5 h-5 bg-[#BFC0C2] p-1 text-black rounded-md mr-2" />
            {title}
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
        </Button>
    );
}

function Tags({ title, onClick }: HeaderVariantProps) {
    return (
        <Button variant="ghost" onClick={onClick} className="w-32">
            <Tag className="w-5 h-5 bg-[#BFC0C2] p-1 text-black rounded-md mr-2" />
            {title}
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
        </Button>
    );
}

function Date({ title, onClick }: HeaderVariantProps) {
    return (
        <Button
            variant="ghost"
            onClick={onClick}
            className="text-xs text-gray-600"
        >
            <Calendar className="w-5 h-5 bg-[#BFC0C2] p-1 text-black rounded-md mr-2" />
            {title}
            <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
    );
}
