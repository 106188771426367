import React, { useCallback, useEffect, useRef, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { ResizableTextarea } from '../../ui/resizable-textarea.tsx';
import FormButton from '../FormButton.tsx';

import useStyles from '../../../Hooks/useStyles.ts';

import { useFormEditorContext } from '../../../Contexts/FormEditorContext';

import { getLetterForIndex } from '../../../Utils/string';

import { cn } from '../../../lib/utils.js';

import { XIcon } from 'lucide-react';

const MAX_BREAK_POINT = 10;

function MultipleChoice() {
    const { getStyles } = useStyles();
    const saveTimeoutRef = useRef<any>(null);
    const [gridLayout, setGridLayout] = useState('');
    const {
        theme,
        editorField,
        setEditorField,
        fields,
        setFields,
        setChanged,
        device,
    } = useFormEditorContext();

    useEffect(() => {
        const layout = editorField?.properties?.choices?.some(
            (choice) => choice?.label?.length > MAX_BREAK_POINT,
        )
            ? 'grid-cols-1'
            : `grid-cols-${device === 'desktop' ? 3 : 2}`;

        const maxHeight =
            device === 'desktop' ? 'max-h-[400px]' : 'max-h-[300px]';
        const combinedLayout = `${layout} ${maxHeight}`;
        setGridLayout(combinedLayout);
    }, [device, editorField?.properties?.choices]);

    const updateField = (fieldId, key, subkey, value) => {
        setFields((prevFields) => {
            const updatedFields = prevFields.map((field) => {
                if (field.id === fieldId) {
                    if (subkey) {
                        return {
                            ...field,
                            [key]: {
                                ...field[key],
                                [subkey]: value,
                            },
                        };
                    } else {
                        return {
                            ...field,
                            [key]: value,
                        };
                    }
                }
                return field;
            });
            return updatedFields;
        });

        setEditorField((prev) => {
            if (subkey) {
                return {
                    ...prev,
                    [key]: {
                        ...prev[key],
                        [subkey]: value,
                    },
                };
            } else {
                return {
                    ...prev,
                    [key]: value,
                };
            }
        });
    };

    const scheduleSave = (updatedField) => {
        if (saveTimeoutRef.current) {
            clearTimeout(saveTimeoutRef.current);
        }
        saveTimeoutRef.current = setTimeout(() => {
            saveChanges(updatedField);
        }, 500); // Debounce save for 500ms
    };

    const handleAddOption = () => {
        const newOption = {
            id: uuidv4(),
            label: '',
            value: '',
        };
        setEditorField((prev) => ({
            ...prev,
            properties: {
                ...prev.properties,
                choices: [...(prev.properties.choices || []), newOption],
            },
        }));
    };

    const saveChanges = useCallback(
        (updatedEditorField) => {
            setChanged(true);
            const newFields = [...fields];
            const index = newFields.findIndex(
                (f) => f?.id === updatedEditorField.id,
            );

            const cleanEditorField = {
                ...updatedEditorField,
                title: updatedEditorField?.title || '',
                properties: {
                    ...updatedEditorField.properties,
                    description:
                        updatedEditorField.properties?.description || '',
                },
            };

            newFields[index] = Object.fromEntries(
                Object.entries(cleanEditorField).filter(
                    ([key, value]) => value != null,
                ),
            );

            setFields(newFields);
        },
        [fields, setFields, setChanged],
    );

    // Cleanup function to clear any pending timeouts
    useEffect(() => {
        return () => {
            if (saveTimeoutRef.current) {
                clearTimeout(saveTimeoutRef.current);
            }
        };
    }, []);

    const handleOnRemoveChoice = (id: string) => {
        const updatedField = {
            ...editorField,
            properties: {
                ...editorField.properties,
                choices: (editorField.properties.choices || []).filter(
                    (choice) => choice.id !== id,
                ),
            },
        };
        setEditorField(updatedField);
        scheduleSave(updatedField);
    };

    return (
        <div className="text-left flex flex-col w-full max-w-[606px] ">
            <ResizableTextarea
                placeholder="Add question title"
                value={editorField?.title || ''}
                onChange={(value) =>
                    updateField(editorField?.id, 'title', null, value)
                }
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles('h2', theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles('h2', theme?.text_size, device)
                        .fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
            />
            <ResizableTextarea
                placeholder="Add question description (Optional)"
                value={editorField?.properties?.description || ''}
                onChange={(value) =>
                    updateField(
                        editorField?.id,
                        'properties',
                        'description',
                        value,
                    )
                }
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles('h4', theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles('h4', theme?.text_size, device)
                        .fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
                className="mt-2"
            />
            <div
                className={cn(
                    `grid gap-2 max-h-[400px] overflow-y-auto mt-4 no-scrollbar`,
                    gridLayout,
                )}
            >
                {editorField?.properties?.choices?.map(
                    (option: any, index: number) => (
                        <Choice
                            id={option?.id}
                            letter={getLetterForIndex(index)}
                            label={option?.label}
                            onChange={(field) => scheduleSave(field)}
                            onKeyDown={handleAddOption}
                            onRemove={handleOnRemoveChoice}
                        />
                    ),
                )}
            </div>

            <div className="w-full mt-2">
                <button
                    style={{
                        color: theme?.answer_text_color,
                    }}
                    onClick={handleAddOption}
                    className="flex justify-start text-sm font-medium underline"
                >
                    Add Choice
                </button>
            </div>
            <FormButton className="mt-3">Next</FormButton>
        </div>
    );
}

export default MultipleChoice;

type ChoiceProps = {
    id: string;
    letter: string;
    label: string;
    onChange: (field: any) => void;
    onKeyDown: (optionId: string, value: string) => void;
    onRemove: (id: string) => void;
};

const Choice = ({
    id,
    letter,
    label,
    onChange,
    onKeyDown,
    onRemove,
}: ChoiceProps) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const { theme, setEditorField } = useFormEditorContext();
    const isAdjustingRef = useRef(false);

    const adjustHeight = useCallback(() => {
        if (!textAreaRef.current || isAdjustingRef.current) return;

        isAdjustingRef.current = true;
        requestAnimationFrame(() => {
            if (!textAreaRef.current) return;

            textAreaRef.current.style.height = 'auto'; // Reset to zero first
            const scrollHeight = textAreaRef.current.scrollHeight;
            textAreaRef.current.style.height = `${scrollHeight}px`;

            isAdjustingRef.current = false;
        });
    }, []);

    // Run adjustment on mount and text changes
    useEffect(() => {
        const timeouts = [0, 50, 100].map((delay) =>
            setTimeout(adjustHeight, delay),
        );

        return () => timeouts.forEach(clearTimeout);
    }, [label, adjustHeight]);

    const adjustWidth = () => {
        if (textAreaRef.current) {
            const length = label.length;
            if (length > MAX_BREAK_POINT) {
                textAreaRef.current.style.width = `100%`;
            } else {
                textAreaRef.current.style.width = '95px';
            }
        }
    };

    useEffect(() => {
        adjustWidth(); // Adjust width whenever content changes
        adjustHeight(); // Adjust height whenever content changes
    }, [label]);

    const handleOnChange = useCallback((value) => {
        setEditorField((prev) => {
            const updatedField = {
                ...prev,
                properties: {
                    ...prev.properties,
                    choices: (prev.properties.choices || []).map((choice) =>
                        choice.id === id
                            ? { ...choice, label: value, value: value }
                            : choice,
                    ),
                },
            };

            onChange(updatedField);
            return updatedField;
        });

        // Maintain focus after state update
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 0);
    }, []);

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            if (e.shiftKey) {
                onChange(`${e.currentTarget.value}\n`);
            } else {
                e.preventDefault();
                onKeyDown(id, e.currentTarget.value);
            }
        }
    };

    return (
        <div
            id={id}
            className="flex flex-row items-center justify-between border border-[#DCDFE4] rounded-lg p-1 max-w-full min-h-[40px] h-fit"
        >
            <div className="flex items-center justify-center bg-[#F1F2F4] rounded-lg text-center w-8 h-8">
                <p
                    style={{
                        color: theme?.answer_text_color,
                    }}
                >
                    {letter}
                </p>
            </div>
            <textarea
                rows={1}
                autoFocus
                ref={textAreaRef}
                placeholder="choice"
                className={
                    'flex-1 shadow-none w-fit text-md px-3 m-0 text-left font-normal bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 border-none outline-none overflow-hidden h-fit max-h-72 transition-height duration-200'
                }
                style={{
                    color: theme?.answer_text_color || theme?.text_color,
                    resize: 'none',
                }}
                value={label}
                onChange={(e) => handleOnChange(e.target.value)}
                onKeyDown={handleOnKeyDown}
            />
            <button
                className="bg-transparent hover:outline-none hover:border-none"
                onClick={() => onRemove(id)}
            >
                <XIcon className="w-[14px] h-[14px] mr-[4px]" />
            </button>
        </div>
    );
};
