'use strict';
import React, { useState, useCallback, useEffect } from 'react';
import tinyColor from 'tinycolor2';
import { useFormContext } from '../../Contexts/FormContext';
import { Button } from '../ui/button.tsx';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../Api/useApi.ts';
import { Plus } from 'lucide-react';
import { BarLoader } from 'react-spinners';
import clsx from 'clsx';

export const FileUploadInput = ({ onChange, field, value, ...props }) => {
    const api = useApi();
    const { formTheme, responseId, formInstance } = useFormContext();
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [file, setFile] = useState(null);
    const [error, setError] = useState<string | null>();
    const [showError, setShowError] = useState(false);
    const fieldId = field?.id as string;
    const allowedFileTypes = field?.properties?.allowed_file_types || [];
    const fileList = formInstance?.getFieldValue(field?.id) || [];
    const maxFileCount = field?.properties?.max_file_count || 2;

    // Map the file types to their corresponding MIME types
    const fileTypeMapping = {
        image: 'image/*',
        video: 'video/*',
        audio: 'audio/*',
        application: 'application/*',
        text: 'text/*',
    };

    // Generate the accept string
    const acceptString = allowedFileTypes
        .map((type) => fileTypeMapping[type]) // Map to MIME types
        .filter(Boolean) // Remove undefined mappings
        .join(','); // Join with commas

    const { mutate: uploadFileMutation, isPending: uploading } = useMutation({
        mutationFn: (file) =>
            api.responses.uploadFile(responseId, fieldId, file),
        onSuccess: (data) => {
            setFile(null);
            const existingFiles = formInstance?.getFieldValue(field?.id) || [];
            onChange([...existingFiles, data?.data]);
        },
        onError: (error) => {
            console.error('File upload error:', error);
        },
    });

    const handleDragLeave = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(false);
    }, []);

    const maxFileSizes = {
        image: 10 * 1024 * 1024, // 10 MB
        video: 100 * 1024 * 1024, // 100 MB
        audio: 50 * 1024 * 1024, // 50 MB
        application: 20 * 1024 * 1024, // 20 MB
        text: 10 * 1024 * 1024, // 10 MB
    };

    const validateFileSize = (file: any) => {
        const type = file?.type?.split('/')[0]; // Get the general type (e.g., "image")
        const maxSize = maxFileSizes[type];
        if (!maxSize) {
            return true; // Allow file if type is not in maxFileSizes
        }
        return file?.size <= maxSize; // Return true if size is valid
    };

    const handleFileChange = (e: any) => {
        const files = e?.target?.files || e?.dataTransfer?.files;
        if (files && files.length > 0) {
            const validFiles = [];
            const filteredFiles = Array.from(files).filter((file) =>
                validateFileSize(file),
            );

            if ((fileList?.length || 0) + filteredFiles.length > maxFileCount) {
                setError('You can only upload up to 2 files.');
                setShowError(true);
                return;
            }

            filteredFiles.forEach((file) => {
                validFiles.push(file);
                uploadFileMutation(file);
            });

            setFile(validFiles);
            setError(''); // Clear previous error
            setShowError(false);
        }
    };

    const handleDragOver = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(true);
    }, []);

    const handleDrop = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(false);
        handleFileChange(e);
    }, []);

    // Add function to handle file deletion
    const handleFileDelete = (fileId: string) => {
        const existingFiles = formInstance?.getFieldValue(field?.id) || [];
        const updatedFiles = existingFiles.filter(
            (file) => file?.id !== fileId,
        );
        onChange(updatedFiles);
    };

    return (
        <div className="flex items-center justify-center w-full">
            <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-start w-full min-h-[136px]  rounded-lg cursor-pointer  "
                style={{
                    backgroundColor: tinyColor(formTheme?.button_color)
                        .setAlpha(0.01)
                        .toRgbString(),
                    borderColor: formTheme?.button_color,
                    borderWidth: '1px',
                    borderStyle: 'dashed',
                    padding: '1rem',
                    opacity: isDragging ? 0.2 : 1,
                }}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {fileList?.length === 0 ? (
                    <>
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                                className="w-8 h-8 mb-4 "
                                style={{ color: formTheme?.text_color }}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 16"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                            </svg>
                            <p
                                className="mb-2 text-[1rem] font-medium "
                                style={{ color: formTheme?.text_color }}
                            >
                                Choose file or drag and drop
                            </p>
                            <p
                                className="flex flex-col text-xs font-normal "
                                style={{
                                    color: tinyColor(formTheme?.text_color)
                                        .setAlpha(0.5)
                                        .toRgbString(),
                                }}
                            >
                                <span>Size limit (10MB max)</span>
                                <span>Max file count: {maxFileCount}</span>
                            </p>
                        </div>
                        <input
                            name={field?.id}
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                            style={{ display: 'none' }}
                            accept={acceptString} // Dynamically set accept attribute
                            onChange={handleFileChange}
                            multiple
                            maxLength={maxFileCount}
                        />
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center w-full space-y-2">
                        {fileList?.map((file) => (
                            <UploadedFileCard
                                key={file.id}
                                file={file}
                                theme={formTheme}
                                onDelete={() => handleFileDelete(file?.id)}
                            />
                        ))}
                        <AddFileButton
                            onClick={handleFileChange}
                            field={field}
                            acceptString={acceptString}
                            count={fileList?.length}
                            maxFileCount={maxFileCount}
                        />
                    </div>
                )}
                {/* {uploading && (
                    <UploadedFileCard
                        file={file}
                        uploading={uploading}
                        theme={formTheme}
                    />
                )} */}
            </label>
        </div>
    );
};

type UploadedFileCardProps = {
    file: any;
    uploading?: boolean;
    theme: any;
    onDelete?: (id?: string | undefined) => void;
};

const UploadedFileCard = ({
    file,
    uploading,
    theme,
    onDelete,
}: UploadedFileCardProps) => {
    const formatFileSize = (sizeInBytes: number): string => {
        const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        let size = sizeInBytes;
        let unitIndex = 0;

        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }

        return `${size?.toFixed(2)} ${units[unitIndex]}`;
    };

    return (
        <div
            className={clsx(
                ' w-full p-2 rounded-[8px] bg-gray-100',
                uploading ? 'bg-gray-50 h-15' : 'bg-gray-100 h-14',
            )}
        >
            <div className="flex items-center justify-between ">
                {/* icon and the name */}
                <div className="flex items-center space-x-2">
                    <div className="flex items-center justify-center w-10 h-10 p-2 bg-white rounded-[8px] ">
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M21 15.3145L17.914 12.2285C17.5389 11.8535 17.0303 11.6429 16.5 11.6429C15.9697 11.6429 15.4611 11.8535 15.086 12.2285L6 21.3145M5 3.31445H19C20.1046 3.31445 21 4.20988 21 5.31445V19.3145C21 20.419 20.1046 21.3145 19 21.3145H5C3.89543 21.3145 3 20.419 3 19.3145V5.31445C3 4.20988 3.89543 3.31445 5 3.31445ZM11 9.31445C11 10.419 10.1046 11.3145 9 11.3145C7.89543 11.3145 7 10.419 7 9.31445C7 8.20988 7.89543 7.31445 9 7.31445C10.1046 7.31445 11 8.20988 11 9.31445Z"
                                stroke="#2563EB"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <p className="text-[1rem] font-normal truncate">
                        {file?.file_name}
                    </p>
                </div>
                {/* size and the delete button */}
                <div className="flex items-center space-x-2">
                    <p className="text-xs font-normal">
                        {formatFileSize(file?.file_size)}
                    </p>
                    <Button
                        variant="ghost"
                        type="button"
                        onClick={() => {
                            onDelete?.(file?.id);
                        }}
                        className="p-3 bg-[#f443360d] rounded-md hover:bg-[#f443360d]"
                    >
                        <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2 4.3151H14M12.6667 4.3151V13.6484C12.6667 14.3151 12 14.9818 11.3333 14.9818H4.66667C4 14.9818 3.33333 14.3151 3.33333 13.6484V4.3151M5.33333 4.3151V2.98177C5.33333 2.3151 6 1.64844 6.66667 1.64844H9.33333C10 1.64844 10.6667 2.3151 10.6667 2.98177V4.3151"
                                stroke="#F44336"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </Button>
                </div>
            </div>
            {uploading && (
                <div className="flex w-full mt-2 h-1/2">
                    <BarLoader
                        color={tinyColor(theme?.button_color)
                            .setAlpha(0.2)
                            .toRgbString()}
                        width={'100%'}
                    />
                </div>
            )}
        </div>
    );
};

type AddFileButtonProps = {
    count: number;
    onClick: (e: any) => void;
    field: any;
    acceptString: string;
    maxFileCount: number;
};
const AddFileButton = ({
    count,
    onClick,
    field,
    acceptString,
    maxFileCount,
}: AddFileButtonProps) => {
    return (
        <>
            <div className=" w-full h-10 px-4 py-2 items-center flex justify-between bg-[#2563eb0d] rounded-[8px]">
                {/* item count  */}
                <div className="flex items-center space-x-2 ">
                    <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.3334 4.31445L6.00008 11.6478L2.66675 8.31445"
                            stroke="#2563EB"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <p className=" text-[#2563eb] text-sm">{count} item</p>
                </div>
                {/* item count and add button */}
                <div className="flex items-center space-x-2 ">
                    <p className=" text-[#2563eb] text-sm">{count} item</p>
                    <Button
                        variant="ghost"
                        type="button"
                        className=" p-0  h-6 w-6 bg-[#2563eb]  hover:bg-[#2563eb] rounded-full "
                        onClick={() =>
                            document.getElementById('dropzone-file')?.click()
                        }
                    >
                        <Plus size={16} className="text-white " />
                    </Button>
                    <input
                        name={field?.id}
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        style={{ display: 'none' }}
                        accept={acceptString} // Dynamically set accept attribute
                        onChange={onClick}
                        multiple
                        maxLength={maxFileCount}
                    />
                </div>
            </div>
        </>
    );
};
