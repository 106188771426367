const API_VERSION: any = process.env.REACT_APP_API_VERSION;
let BACKEND_URL: string = process.env.REACT_APP_SERVICE_URL + API_VERSION;

// if backend_url has a trailing slash, remove it
if (BACKEND_URL.charAt(BACKEND_URL.length - 1) === '/') {
    BACKEND_URL = BACKEND_URL.slice(0, -1);
}

export const ENDPOINTS = {
    forms: {
        getOne: (form_id: string) => `${BACKEND_URL}/forms/${form_id}`,
        getList: (workspace_id: string) =>
            `${BACKEND_URL}/forms/?workspace_id=${workspace_id}`,
        createOne: `${BACKEND_URL}/forms/`,
        deleteOne: (form_id: string) => `${BACKEND_URL}/forms/${form_id}`,
        updateOne: (form_id: string) => `${BACKEND_URL}/forms/${form_id}`,
        getFormResponses: (form_id: string, queryParams?: string) =>
            `${BACKEND_URL}/responses/forms/${form_id}${
                queryParams ? `?${queryParams}` : ''
            }`,
        deleteFormResponse: (form_id: string, queryParams?: string) =>
            `${BACKEND_URL}/responses/forms/${form_id}${
                queryParams ? `?${queryParams}` : ''
            }`,
        exportFormResponses: (
            workspace_id: string,
            form_id?: string,
            responseIds?: string[],
        ) => {
            if (form_id)
                return `${BACKEND_URL}/export/responses/?workspace_id=${workspace_id}&form_id=${form_id}`;
            return `${BACKEND_URL}/export/responses/?workspace_id=${workspace_id}&response_ids=${responseIds?.join(
                ',',
            )}`;
        },
    },
    revisions: {
        createOne: (queryParams: string) =>
            `${BACKEND_URL}/revisions/${queryParams ? `?${queryParams}` : ''}`,
        getAll: (workspace_id: string) =>
            `${BACKEND_URL}/revisions/?workspace_id=${workspace_id}`,
        getOne: (id: string, workspace_id: string, recordType: string) =>
            `${BACKEND_URL}/revisions/${id}?workspace_id=${workspace_id}&record_type=${recordType}`,
        deleteOne: (form_id: string) =>
            `${BACKEND_URL}/revisions/?form_id=${form_id}`,
    },
    aiForms: {
        createOne: `${BACKEND_URL}/ai_form/forms/`,
    },
    responses: {
        createOne: (form_id: string) =>
            `${BACKEND_URL}/responses/?form_id=${form_id}`,
        updateOne: (response_id: string, signature: string) =>
            `${BACKEND_URL}/responses/${response_id}?signature=${signature}/`,
        getResponses: (form_id: string) => `${BACKEND_URL}/responses/`,
        uploadFile: (responseId: string, fieldId: string) =>
            `${BACKEND_URL}/files/upload/responses/${responseId}?field_id=${fieldId}`,
    },
    files: {
        upload: (theme_id: any) =>
            `${BACKEND_URL}/files/upload?form_id=${theme_id}`,
        getFiles: (file_id: string) => `${BACKEND_URL}/files/`,
    },
    workspace: {
        createOne: `${BACKEND_URL}/workspaces/`,
        getList: `${BACKEND_URL}/workspaces/`,
        deleteOne: (worksapce_id: string) =>
            ` ${BACKEND_URL}/workspaces/?workspace_id=${worksapce_id}`,
        updateOne: (worksapce_id: string) =>
            ` ${BACKEND_URL}/workspaces/?workspace_id=${worksapce_id}`,
    },
    utils: {
        getCountries: `${BACKEND_URL}/utils/`,
    },
    theme: {
        createOne: `${BACKEND_URL}/themes/`,
        getAtheme: (theme_id: string) => `${BACKEND_URL}/${theme_id}`,
        updateAtheme: (theme_id: string) => `${BACKEND_URL}/themes/${theme_id}`,
        deleteAtheme: (theme_id: string) => `${BACKEND_URL}/${theme_id}`,
    },
    Dashboard: {
        getStats: (workspace_id: string, time_interval: string) =>
            `${BACKEND_URL}/dashboard/workspace-stats?workspace_id=${workspace_id}&filter=${time_interval}`,
    },
};
